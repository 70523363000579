.non-investor--next-step--image {
  padding: var(--nk-space-inset-lg);
  max-height: 225px;
  display: block;
  margin: 0 auto;
}

.non-investor--row--align-center {
  align-items: center;
}
