@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '~@nutkit/styles/src/base/mixins/breakpoints.scss';

.container {
  @include nk-only-mobile {
    padding: var(--nk-grid-dynamic-container-inset-xl);
  }

  @include nk-small-tablet {
    padding: var(--nk-grid-dynamic-container-inset-lg);
  }

  @include nk-tablet {
    padding: var(--nk-grid-dynamic-container-inset-lg);
  }

  @include nk-desktop {
    padding: var(--nk-grid-dynamic-container-inset-lg);
  }

  @include nk-desktop-large {
    padding: 6.25rem;
  }
}

.support-notification {
  background-color: var(--nk-background-color-base);
  box-shadow: none;
  border: 1px solid var(--nk-panel-border-color);
  margin-bottom: var(--nk-space-stack-xl);
}

.required-item-list-container {
  margin-bottom: var(--nk-space-stack-xl);
}
