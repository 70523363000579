@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '~@nutkit/styles/src/base/mixins/breakpoints.scss';

.layout-container {
  background-color: var(--nk-color-white);

  @include nk-small-tablet {
    padding: 0;
  }

  @include nk-only-mobile {
    padding: 0;
  }
}
