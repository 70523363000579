@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '~@nutkit/styles/src/base/mixins/breakpoints.scss';

.adaptiveContainer {
  @include nk-tablet-down {
    width: 100%;
  }

  @include nk-tablet {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  @include nk-desktop {
    max-width: 410px;
  }
}

.nutmegAdaptiveContainer {
  @include nk-tablet {
    width: 360px;
  }

  @include nk-desktop {
    width: 80%;
    max-width: 400px;
  }
}
