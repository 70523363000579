@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
.check--circle-completed {
  color: var(--nk-color-feedback-success-default);
  // stylelint-disable-next-line selector-pseudo-class-no-unknown
  :global {
    circle {
      fill: currentColor;
    }

    path {
      fill: var(--nk-color-white);
    }
  }
}
