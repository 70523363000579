@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '~@nutkit/styles/src/base/mixins/breakpoints.scss';

.container {
  background-color: var(--nk-color-black);
  min-height: 100vh;
  height: 100%;
}

.banner-container {
  background-color: inherit;
  min-height: 100vh;
  height: 100%;
  max-width: 100%;

  @include nk-only-mobile {
    padding: var(--nk-grid-dynamic-container-inset-xl);
  }

  @include nk-small-tablet {
    padding: var(--nk-grid-dynamic-container-inset-lg);
  }

  @include nk-tablet {
    padding: 5rem;
  }

  @include nk-desktop {
    padding: var(--nk-grid-dynamic-container-inset-lg);
  }

  @include nk-desktop-large {
    padding: 6.25rem;
  }
}

.divider {
  border-color: var(--nk-color-grey-xx-light);
  margin-bottom: var(--nk-space-stack-lg);
}
