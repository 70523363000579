@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
.footer {
  display: flex;
  padding: var(--nk-space-inset-lg) 0;
  align-items: center;
}

.divider {
  border-left: 1px solid var(--nk-color-black);
  height: var(--nk-space-stack-xl);
  margin: 0 var(--nk-space-stack-md) 0 var(--nk-space-stack-lg);
}
