@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
@import '~@nutkit/styles/src/base/mixins/breakpoints.scss';

.support-panel {
  background-color: var(--nk-background-color-base);
  box-shadow: none;
  border: 1px solid var(--nk-panel-border-color);
  margin-bottom: var(--nk-space-stack-xl);
}

.support-detail-container,
.support-detail-item {
  display: flex;
}

.support-detail-container {
  @include nk-only-mobile {
    flex-direction: column;
  }
}

.support-detail-item {
  color: var(--nk-text-color-primary);
  gap: var(--nk-space-stack-sm);
  width: 50%;
}
