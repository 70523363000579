@import "~@nutkit/styles/src/base/nutmeg-gbr-bigbear/tokens.scss";$product: nutmeg-gbr-bigbear !global;
.container {
  background-color: var(--nk-color-black);
  min-height: 100vh;
  height: 100%;
}

.jlfContainer {
  background-color: var(--nk-color-white);
  min-height: 100vh;
  height: 100%;
}
